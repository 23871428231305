<template>
  <v-container fluid>
    <TableViewComponent
      title="Table transmissions > compositions cellules clients"
      @addItemEvent="onItem()"
      addItemLabel="ajouter une composition de cellule client"
      itemLabel="composition de cellule client"
      itemsLabel="compositions de cellules clients"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette composition de cellule client ?"
      :pagination="false"
      :loading="loading"
      :vcols="{ xl: '6', lg: '8', md: '10', sm: '12', xs: '12' }"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { CustomerCellsService } from "@/service/conf/transmission/customer_cells_service.js";
import { AvecProductsService } from "@/service/conf/avec_products_service.js";
import { PerimetersService } from "@/service/conf/transmission/perimeters_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableCustomerCells",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /** le service des entités */
      service: null,
      /** le service des activités */
      serviceProducts: null,
      /** le service des sous-activités */
      servicePerimeters: null,
      
      /** les éléments */
      entities: [],
    };
  },
  methods: {
    onItem() {
      this.$router.push(routes.transmission.customerCells.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.entities = [];
        // Récupération des cellules client
        let entities = await this.service.getAll();
        // Récupération des produits
        let products = await this.serviceProducts.getAll();
        // Récupération des périmètres
        let perimeters = await this.servicePerimeters.getAll();

        
        entities.forEach((e) => {

          let entity = JSON.parse(JSON.stringify(e));

          // Récupération du produit de la cellule client
          let foundProduct = products.find((p) => p.id == entity.avecProduct);
          // Ajout du nom du produit
          entity.avecProductName = foundProduct.digitalName;

          // Parcours de la liste des périmètres
          let allPerimetersName = [];
          for (let i of entity.items) {

            // Récupération du périmètre de la cellule client
            let foundPerimeter = perimeters.find((p) => p.id == i.perimeter);
            // Ajout du nom du périmètre
            i.perimeterName = foundPerimeter.label;

            // Ajout du nom du périmètre au tableau
            allPerimetersName.push(i.perimeterName);
          }
          // Association de tous les noms de périmètre séparés par une virgule
          entity.allPerimetersName = allPerimetersName.join(', ');

          //action de consultation
          entity.view = () => {
            this.$router.push(
              routes.transmission.customerCells.view.root + "/" + entity.id
            );
          };

          //action de modification
          entity.edit = () => {
            this.$router.push(
              routes.transmission.customerCells.edit.root + "/" + entity.id
            );
          };

          //action de suppression
          entity.delete = async () => {
            try {
              await this.service.delete(entity.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression du produit : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.entities.push(entity);
        });
        
        // Tri des composition de cellules client par nom de produits
        this.entities.sort(function(a, b) {
          return a.avecProductName.localeCompare(b.avecProductName);
        });


      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Produit",
          align: "start",
          sortable: true,
          value: "avecProductName",
          default: true,
        },
        {
          text: "Périmètre(s)",
          align: "start",
          sortable: true,
          value: "allPerimetersName",
          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditConf];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditConf, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadConf, ];
    },
  },
  mounted() {
    
    this.service = new CustomerCellsService(this.$api.getCustomerCellsApi());
    this.serviceProducts = new AvecProductsService(this.$api.getTransmissionsAvecProductsApi());
    this.servicePerimeters = new PerimetersService(this.$api.getPerimetersApi());

    this.load();
  },
};
</script>

<style>
</style>